<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row
          align="center"
          justify="space-around"
          class="d-flex"
        >
          <v-col
            cols="12"
            sm="4"
            md="2"
            lg="2"
            class="my-0 py-0"
          >
            <div>
              <v-select
                v-model="estado"
                :items="estados"
                label="Estado"
                outlined
                placeholder=" "
                @change="mudandoMunicipioDevidoEstado"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
            class="my-0 py-0"
          >
            <v-autocomplete
              v-model="municipio"
              :items="municipios"
              multiple
              :disabled="!estado || municipios.length === 0"
              item-text="municipio.nome"
              item-value="municipio.nome"
              label="Município"
              outlined
              :placeholder="estado ? ' ' : 'Selecione o estado'"
            />
          </v-col>
          <v-col
            md="4"
            class="my-0 py-1"
          >
            <v-text-field
              v-model="aerodromo"
              :rules="[rules.min2,rules.max100]"
              label="Aeródromo (Nome, CIAD ou Código ICAO)"
              outlined
              placeholder=" "
            />
          </v-col>
          <v-col
            md="3"
            class="my-0 py-0"
            style="apadding-bottom: 31px !important;"
          >
            <v-btn
              class="py-4 mx-1"
              color="primary"
              dark
              depressed
              @click="buscandoPlanos(estado,municipio,aerodromo,quantidade,1)"
            >
              {{ $t('button.search') }}

              <span
                v-if="planos.length > 0"
                class="ml-2"
              >
                ({{ total }})
              </span>
            </v-btn>
            <v-btn
              class="py-4 mx-1"
              color="warning"
              dark
              depressed
              @click="limparFiltros()"
            >
              {{ $t('button.clear') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-container
          v-if="planos.length > 0 && quantidadePaginas !== 1"
          class="max-width ma-0 pa-0"
        >
          <v-row
            align="baseline"
            justify="space-around"
          >
            <v-col
              cols="auto"
            >
              <v-pagination
                v-model="page"
                class="my-1"
                :total-visible="totalVisible"
                :length="quantidadePaginas"
                @input="buscandoPlanos(estado,municipio,aerodromo,quantidade,page)"
              />
            </v-col>
            <!-- <v-col
              cols="auto"
            >
              <v-select
                v-model="quantidade"
                :items="quantidadeResultadoPorPagina"
                label="Quantidade por página"
                solo
                rounded
              />
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card flat>
      <v-card-title>
        <h4 class="text-h6">
          {{ tituloTabela }}
        </h4>
      </v-card-title>
      <v-spacer />
      <v-data-table
        :headers="tabela"
        :items="planos"
        item-key="id"
        show-expand
        single-expand
        disable-sort
        dense
        :loading="tablePlanosLoading"
        :loading-text="$t('planos.resultado.table_loading')"
        hide-default-footer
        :expanded.sync="expanded"
      >
        <template v-slot:body="{items}">
          <tbody
            v-for="item in items"
            :key="item.value"
          >
            <tr>
              <td />
              <template v-for="(coluna, indexColuna) in tabela">
                <td
                  v-if="coluna.mostrarColuna"
                  :key="coluna.text + '_0_' + indexColuna"
                >
                  <template v-if="coluna.link">
                    <!-- eslint-disable-next-line max-len -->
                    <a :href="`${apiSysAGAAntigo}/${coluna.link.estatico}${ item[coluna.link.key] }`">
                      <template v-if="coluna.type === 'string'">
                        {{ item[coluna.value] }}
                      </template>
                      <template v-if="coluna.type === 'closure'">
                        {{ coluna.value(item) }}
                      </template>
                      <template v-if="coluna.type === 'date'">
                        {{ item[coluna.value] | moment("DD.MM.YYYY") }}
                      </template>
                      <template v-if="coluna.type === 'object'">
                        {{ item[coluna.value][coluna.filho] }}
                      </template>
                    </a>
                  </template>
                  <template v-else>
                    <template v-if="coluna.type === 'string'">
                      {{ item[coluna.value] }}
                    </template>
                    <template v-if="coluna.type === 'closure'">
                      {{ coluna.value(item) }}
                    </template>
                    <template v-if="coluna.type === 'date'">
                      {{ item[coluna.value] | moment("DD.MM.YYYY") }}
                    </template>
                    <template v-if="coluna.type === 'object'">
                      {{ item[coluna.value][coluna.filho] }}
                    </template>
                  </template>
                  <template v-if="coluna.type === 'array' && coluna.colunaComLength">
                    <v-chip
                      color="blue"
                      x-small
                      dark
                      class="ml-3"
                      @click="clicked(item.expandedShow = !item.expandedShow)"
                    >
                      <span class="mr-2">
                        {{ item[coluna.value].length }}
                      </span>
                      <v-icon
                        v-if="item.expandedShow"
                        dark
                      >
                        mdi-chevron-down
                      </v-icon>
                      <v-icon
                        v-if="!item.expandedShow"
                        dark
                      >
                        mdi-chevron-up
                      </v-icon>
                    </v-chip>
                  </template>
                </td>
              </template>
            </tr>
            <tr v-if="item.expandedShow">
              <td
                :colspan="+tabela.length + 1"
                class="elevation-3  lighten-4 pb-2"
              >
                <v-row
                  class="mx-0 my-0"
                  align="center"
                  justify="center"
                >
                  <template v-for="(coluna, indexColuna) in tabela">
                    <v-col
                      v-if="coluna.expanded"
                      :key="coluna.text + '_0_' + indexColuna"
                      cols="12"
                      sm="6"
                      class="my-0 py-0"
                    >
                      <v-card class="mx-auto my-2">
                        <v-card-text>
                          <p class="font-weight-medium text--primary">
                            {{ coluna.text }}
                          </p>
                          <div
                            v-if="coluna.type === 'array' "
                            class="text--primary"
                          >
                            <template v-if="coluna.expanded.type === 'list'">
                              <ul
                                v-for="(filho, index) in
                                  orderBy(item[coluna.value], coluna.expanded.itensFor[0])"
                                :key="index"
                              >
                                <li :key="index">
                                  <span
                                    v-for="(colunasFilho, colunasFilhoIndex) in
                                      coluna.expanded.itensFor.slice().reverse()"
                                    :key="colunasFilhoIndex + '_0'"
                                  >
                                    {{ filho[colunasFilho] }}
                                    <!-- eslint-disable-next-line max-len -->
                                    <template v-if="colunasFilhoIndex !== coluna.expanded.itensFor.length - 1 ">
                                      {{ coluna.expanded.separadorItens }}
                                    </template>
                                  </span>
                                </li>
                              </ul>
                            </template>
                            <template v-if="coluna.expanded.type === 'chip'">
                              <div class="text--primary">
                                <v-row
                                  class="mb-5 mt-5"
                                  align="center"
                                >
                                  <v-col
                                    v-for="(filho) in
                                      orderBy(item[coluna.value], coluna.expanded.itensFor[0])"
                                    :key="filho.id"
                                    cols="12"
                                    sm="auto"
                                    class="my-0 py-0"
                                  >
                                    <!-- eslint-disable-next-line max-len -->
                                    <a :href="`${apiSysAGAAntigo}/${coluna.expanded.link.estatico}${ filho[coluna.expanded.link.key] }`">
                                      <template v-if="coluna.expanded.classeCondicionada ">

                                        <!-- eslint-disable max-len -->
                                        <v-chip
                                          dark
                                          x-small
                                          :class="
                                            filho[coluna.expanded.classeCondicionada.item].toUpperCase()
                                              !==
                                              coluna.expanded.classeCondicionada.valor ?
                                                coluna.expanded.classeCondicionada.recebeClasseIgual :
                                                coluna.expanded.classeCondicionada.recebeClasseDiferente "
                                        >
                                          <span
                                            v-for="(colunasFilho, colunasFilhoIndex) in
                                              coluna.expanded.itensFor.slice().reverse()"
                                            :key="colunasFilhoIndex + '_0'"
                                          >
                                            {{ filho[colunasFilho].toUpperCase() }}
                                            <!-- eslint-disable-next-line max-len -->
                                            <template v-if="colunasFilhoIndex !== coluna.expanded.itensFor.length - 1 ">
                                              {{ coluna.expanded.separadorItens }}
                                            </template>
                                          </span>
                                        </v-chip>
                                      </template>
                                      <template v-else>
                                        <v-chip
                                          dark
                                          x-small
                                        >
                                          <span
                                            v-for="(colunasFilho, colunasFilhoIndex) in
                                              coluna.expanded.itensFor.slice().reverse()"
                                            :key="colunasFilhoIndex + '_0'"
                                          >
                                            {{ filho[colunasFilho].toUpperCase() }}
                                            <!-- eslint-disable-next-line max-len -->
                                            <template v-if="colunasFilhoIndex !== coluna.expanded.itensFor.length - 1 ">
                                              {{ coluna.expanded.separadorItens }}
                                            </template>
                                          </span>
                                        </v-chip>
                                      </template>
                                    </a>
                                  </v-col>
                                </v-row>
                              </div>
                            </template>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
// import buscarEstados from '../../../utils/buscarEstadosPlanos';
// import buscarMunicipiosAerodromos from '../../../utils/buscarMunicipiosAerodromosPlanos';
import buscarMunicipiosIBGE from '../../../utils/buscarMunicipiosIBGE';

export default {
  name: 'PortalBuscaResultadoTabelaApi',
  mixins: [Vue2Filters.mixin],
  props: {
    tipoApi: {
      type: String,
      default: 'pública',
    },
    urlApi: {
      type: String,
      default: '',
    },
    tituloTabela: {
      type: String,
      default: '',
    },
    tabela: {
      type: Array,
      default: () => [],
    },
    quantidadeResultadoPorPagina: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tablePlanosLoading: false,
    apiSysAGANovo: '',
    apiSysAGAAntigo: '',
    expanded: [],
    planos: [],
    total: null,
    perPage: null,
    quantidadePaginas: 1,
    totalVisible: 7,
    from: null,
    to: null,
    page: 1,
    estado: '',
    municipio: [],
    aerodromo: '',
    quantidade: 5,
    estados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'],
    municipios: [],
    rules: {
      required: (value) => !!value || 'Required.',
      max100: (value) => (value || '').length <= 100 || 'Max 100 characters',
      min4: (value) => (value && value.length >= 4) || 'Min 4 characters',
      min2: (value) => (value && value.length >= 2) || 'Min 2 characters',
    },
    search: '',
  }),
  computed: {
  },
  async mounted() {
    this.apiSysAGANovo = process.env.VUE_APP_URL_API;
    this.apiSysAGAAntigo = process.env.VUE_APP_URL_SysAGA2;
  },
  methods: {
    clicked(id) {
      if (this.expanded && this.expanded[0] && this.expanded[0].id === id) {
        this.expanded = [];
      } else {
        const achandoLinha = this.planos.filter((cada) => cada.id === id);
        this.expanded = achandoLinha;
      }
    },
    limparFiltros() {
      this.estado = '';
      this.municipio = [];
      this.planos = [];
      this.aerodromo = '';
    },
    mudandoMunicipioDevidoEstado() {
      const { estado } = this;
      if (estado) {
        buscarMunicipiosIBGE(estado).then((retorno) => {
          this.municipios = retorno;
        });

        // buscarMunicipiosAerodromos(estado).then((retorno) => {
        //   this.municipios = retorno;
        // });
      }
    },
    async buscandoPlanos(estado, municipio, aerodromo, quantidade, page) {
      this.tablePlanosLoading = true;
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      await this.$axios.post(`${apiurl}?page=${page}`, {
        estado,
        municipio,
        aerodromo,
        quantidade,
      })
        .then((response) => {
          const planos = response.data.downloads;
          this.total = planos.total;
          this.perPage = planos.per_page;
          this.from = planos.from;
          this.to = planos.to;
          this.page = planos.current_page;
          this.planos = planos.data;
          this.tablePlanosLoading = false;
          this.quantidadePaginas = Math.ceil(this.total / this.perPage);
          this.totalVisible = this.quantidadePaginas;
          if (this.quantidadePaginas > 7) {
            this.totalVisible = 7;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
