import axios from 'axios';
import buscarEstadosIBGE from './buscarEstadosIBGE';
import ordenacaoArrayComObjetos from './funcoesJs/ordenacaoArrayComObjetos';

export default async (estado) => {
  const estadoSelecionado = buscarEstadosIBGE.filter((cadaUm) => (cadaUm.sigla === estado));
  const estadoId = estadoSelecionado[0].id;
  const apiurl = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`;

  const retorno = await axios.get(apiurl)
    .then((response) => {
      const { data } = response;
      const lista = data.sort(ordenacaoArrayComObjetos('nome'));
      const listaRetorno = [];
      lista.forEach((element) => {
        listaRetorno.push(element.nome);
      });
      return listaRetorno;
    })
    .catch((error) => {
      console.log(error);
    });
  return retorno;
};
