<template>
  <v-container
    fluid
    class="fill-height pa-0"
  >
    <v-container
      fluid
      class="white pa-12"
    >
      <h2 class="text-h3 text-center font-weight-bold mb-10">
        {{ $t('planos.titulo') }}
      </h2>
      <h3 class="text-h4 text-center font-weight-bold mb-5">
        {{ $t('planos.tipos.titulo') }}
      </h3>
      <v-container class="d-flex">
        <v-row justify="center">
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            :lg="4"
          >
            <h3 class="text-h6 text-uppercase color-blue">
              {{ $t('planos.tipos.pbzpa.titulo') }}
            </h3>
            <p class="text-justify">
              {{ $t('planos.tipos.pbzpa.texto') }}
            </p>
          </v-col>
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            :lg="4"
          >
            <h3 class="text-h6 text-uppercase color-blue">
              {{ $t('planos.tipos.pbzph.titulo') }}
            </h3>
            <p class="text-justify">
              {{ $t('planos.tipos.pbzph.texto') }}
            </p>
          </v-col>
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            :lg="4"
          >
            <h3 class="text-h6 text-uppercase color-blue">
              {{ $t('planos.tipos.pezpa.titulo') }}
            </h3>
            <p class="text-justify">
              {{ $t('planos.tipos.pezpa.texto') }}
            </p>
          </v-col>
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            :lg="4"
          >
            <h3 class="text-h6 text-uppercase color-blue">
              {{ $t('planos.tipos.pzpana.titulo') }}
            </h3>
            <p class="text-justify">
              {{ $t('planos.tipos.pzpana.texto') }}
            </p>
          </v-col>
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            :lg="4"
          >
            <h3 class="text-h6 text-uppercase color-blue">
              {{ $t('planos.tipos.pzpreah.titulo') }}
            </h3>
            <p class="text-justify">
              {{ $t('planos.tipos.pzpreah.texto') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container>
      <v-alert
        v-show="elementVisible"
        dense
        text
        type="success"
        class="hideElement"
      >
        Cadastro realizado sucesso!
      </v-alert>
      <v-alert
        v-show="elementError"
        id="listErros"
        dense
        text
        type="error"
        class="hideElement"
      >
        {{ 'Error ao finalizar cadastro!' }}
        <br>
        <br>
      </v-alert>
      <!-- <v-col>
        <v-card
          class="pa-5"
          :elevation="6"
          rounded="xl"
          style="background-color:#D9EDF7"
        >
          <v-icon
            color="dark"
          >
            mdi-email
          </v-icon>
          <div style="color: gray">
            Quer ser avisado quando um novo plano for publicado?
          </div>
          <div class="text-center">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{on, attrs}">
                <div style="padding: 20px !important;">
                  <v-btn
                    class="white--text"
                    small
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('footer.cadastrar') }}
                  </v-btn>
                </div>
              </template>

              <v-card>
                <v-card-title class="text-h5 lighten-2">
                  {{ $t('footer.assinar') }}
                </v-card-title>
                <v-col
                  cols="12"
                >
                  <v-row>
                    <div
                      class="text--primary"
                      style="margin: 5px; border: 0px solid black;"
                    >
                      {{ $t('footer.subtitle') }}
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="nome"
                    label="Nome"
                    :rules="nameRules"
                    outlined
                    clearable
                  />
                  <v-text-field
                    v-model="mail"
                    label="E-mail"
                    :rules="emailRules"
                    outlined
                    clearable
                  />
                  <v-select
                    v-model="estado"
                    :items="estados"
                    label="Estado"
                    :rules="stateRules"
                    outlined
                    clearable
                    placeholder=" "
                    @change="mudandoMunicipioDevidoEstado"
                  />
                  <v-autocomplete
                    v-model="municipio"
                    :items="municipios"
                    multiple
                    :disabled="!estado || municipios.length === 0"
                    item-text="municipios.nome"
                    item-value="municipios.nome"
                    label="Cidade"
                    outlined
                    :placeholder="estado ? ' ' : 'Selecione o estado'"
                  />
                </v-col>

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeModal()"
                  >
                    {{ 'Cancelar' }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="subscribe(nome ,mail ,estado , municipio),clearBox('listErros')"
                  >
                    {{ 'Cadastrar' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </v-col> -->
    </v-container>
    <v-container
      fluid
      class="pa-10"
    >
      <v-container>
        <v-card
          class="pa-10"
          :elevation="6"
          rounded="xl"
        >
          <v-card-title>
            <v-spacer />
            <h2 class="text-h4 font-weight-bold text-center">
              {{ $t('planos.card.titulo') }}
            </h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <PageBuscaResultadoTabelaApi
              :quantidade-resultado-por-pagina="[5,10]"
              url-api="portal/planos"
              tipo-api="pública"
              :titulo-tabela="camposBusca.tituloTabela"
              :tabela="camposBusca.tabela"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import PageBuscaResultadoTabelaApi from '../widget/PortalBuscaResultadoTabelaApi.vue';
import buscarEstados from '../../../utils/listaUf';
import buscarMunicipiosIBGE from '../../../utils/buscarMunicipiosIBGE';

export default {
  name: 'Planos',
  components: {
    PageBuscaResultadoTabelaApi,
  },
  data: () => ({
    mail: '',
    nome: '',
    name: '',
    email: '',
    state: '',
    city: '',
    estado: '',
    municipio: [],
    aerodromo: '',
    quantidade: 5,
    estados: [],
    municipios: [],
    info: null,
    form: {
      name: '',
      email: '',
      estado: '',
      municipio: '',
    },
    msgErro: '',
    dialog: false,
    elementVisible: false,
    elementError: false,
    elementMsg: '',
    list: '',
    emailRules: [
      (v) => !!v || 'E-mail é obrigatório',
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email não é valido',
    ],
    nameRules: [
      (v) => !!v || 'Nome é obrigatório',
      (v) => (v && v.length >= 2) || 'Nome não pode conter menos de 2 caracteres',
      (v) => (v && v.length <= 100) || 'O campo nome de ter no maxímo 100 caracteres',
    ],
    stateRules: [
      (v) => !!v || 'Estado é obrigatório',
    ],
    cityRules: [
      (v) => !!v || 'Cidade é obrigatório',
    ],
    errorTam: '',
  }),
  computed: {
    camposBusca() {
      const retorno = {};
      retorno.tituloTabela = this.$t('planos.resultados_busca');
      retorno.tabela = [
        {
          item: 'aerodromo',
          text: this.$t('planos.resultado.table_headers.nome_aerodromo'),
          align: 'start',
          sortable: true,
          value: 'aerodromo',
          mostrarColuna: true,
          type: 'object',
          filho: 'denominacao',

        },
        {
          item: 'aerodromo',
          text: this.$t('planos.resultado.table_headers.oaci'),
          value: 'aerodromo',
          mostrarColuna: true,
          type: 'object',
          filho: 'codOACI',
        },
        {
          item: 'tipo',
          text: this.$t('planos.resultado.table_headers.tipo'),
          value: 'tipo',
          mostrarColuna: true,
          type: 'string',
        },
        {
          item: 'portaria_dt',
          text: this.$t('planos.resultado.table_headers.data'),
          value: 'portaria_dt',
          mostrarColuna: true,
          type: 'date',
          link: {
            estatico: 'portaria-portal/',
            key: 'id',
            api: 'sysaga2',
          },
        },
        {
          item: 'portaria_n',
          text: this.$t('planos.resultado.table_headers.portaria'),
          value: 'portaria_n',
          mostrarColuna: true,
          type: 'string',
          link: {
            estatico: 'portaria-portal/',
            key: 'id',
            api: 'sysaga2',
          },
        },
        {
          item: 'municipios',
          text: this.$t('planos.resultado.table_headers.municipios_impactados'),
          value: 'municipios',
          mostrarColuna: true,
          type: 'array',
          colunaComLength: true,
          expanded: {
            type: 'list',
            headers: this.$t('planos.resultado.table_headers.municipios_impactados'),
            itensFor: ['municipio', 'estadoSigla'],
            separadorItens: ' - ',
          },
        },
        {
          item: 'anexos',
          text: this.$t('planos.resultado.table_headers.zonas_protecao'),
          value: 'anexos',
          mostrarColuna: true,
          type: 'array',
          colunaComLength: true,
          expanded: {
            type: 'chip',
            headers: this.$t('planos.resultado.table_headers.municipios_impactados'),
            itensFor: ['extensao', 'tipo'],
            separadorItens: ' . ',
            link: {
              estatico: 'planos-portal/',
              key: 'id',
            },
            classeCondicionada:
              {
                item: 'extensao',
                valor: 'PDF',
                recebeClasseIgual: 'blue',
                recebeClasseDiferente: 'indigo',
              },
          },
        },
      ];
      return retorno;
    },
    camposPageCardAccordion() {
      const retorno = {};
      retorno.dados = [
        {
          title: this.$t('planos.tipos.pbzpa.titulo'),
          text: this.$t('planos.tipos.pbzpa.texto'),
        },
        {
          title: this.$t('planos.tipos.pbzph.titulo'),
          text: this.$t('planos.tipos.pbzph.texto'),
        },
        {
          title: this.$t('planos.tipos.pzpana.titulo'),
          text: this.$t('planos.tipos.pzpana.texto'),
        },
        {
          title: this.$t('planos.tipos.pezpa.titulo'),
          text: this.$t('planos.tipos.pezpa.texto'),
        },
        {
          title: this.$t('planos.tipos.pzpreah.titulo'),
          text: this.$t('planos.tipos.pzpreah.texto'),
        },
      ];
      return retorno;
    },
  },
  mounted() {
    this.estados = [...buscarEstados];
    this.apiSysAGANovo = process.env.VUE_APP_URL_API;
    this.apiSysAGAAntigo = process.env.VUE_APP_URL_SysAGA2;
  },
  methods: {
    resetInput() {
      this.nome = '';
      this.mail = '';
      this.estado = '';
      this.municipio = '';
    },
    mudandoMunicipioDevidoEstado() {
      const { estado } = this;
      if (estado) {
        buscarMunicipiosIBGE(estado).then((retorno) => {
          this.municipios = retorno;
        });
      }
    },
    async buscandoPlanos(estado, municipio, aerodromo, quantidade, page) {
      console.log(estado, municipio, aerodromo, quantidade, page);
      this.tablePlanosLoading = true;
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      await this.$axios.post(`${apiurl}?page=${page}`, {
        estado,
        municipio,
        aerodromo,
        quantidade,
      })
        .then((response) => {
          const planos = response.data.downloads;
          console.log('planos');
          console.log(planos);
          this.total = planos.total;
          this.perPage = planos.per_page;
          this.from = planos.from;
          this.to = planos.to;
          this.page = planos.current_page;
          this.planos = planos.data;
          this.tablePlanosLoading = false;
          this.quantidadePaginas = Math.ceil(this.total / this.perPage);
          this.totalVisible = this.quantidadePaginas;
          if (this.quantidadePaginas > 7) {
            this.totalVisible = 7;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async subscribe(nome, mail, estado, municipio) {
      const apiurl = `${process.env.VUE_APP_URL_API}/portal/subscribe`;
      const city = municipio[0];
      const params = {
        name: nome,
        email: mail,
        state: estado,
        city,
      };
      await this.$axios.post(`${apiurl}`, params)
        .then((response) => {
          console.log(response.statusText);
          // eslint-disable-next-line no-return-assign
          setTimeout(() => this.elementVisible = true, 1000);
          this.dialog = false;
          this.resetInput();
        })
        .catch((error) => {
          const msgErro = error.response.data.errors;
          const newArray = [];
          Object.entries(msgErro).forEach((value) => newArray.push(value[1]));
          // eslint-disable-next-line no-unused-vars
          this.list = document.createElement('ul');
          this.list.style.marginLeft = '10px';
          // eslint-disable-next-line no-shadow
          newArray.forEach((newArray) => {
            const li = document.createElement('li');
            li.textContent = newArray;
            this.list.appendChild(li);
          });
          this.errorTam = newArray;
          console.log(newArray.length);
          if (newArray.length > 0) {
            // eslint-disable-next-line no-return-assign
            setTimeout(() => this.elementError = false, 1000);
            const listErros = document.querySelector('#listErros');
            listErros.appendChild(this.list);
          }
        });
    },
    closeModal() {
      console.log('Fecha');
      this.dialog = false;
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.elementError = false, 1000);
      // this.isModalVisible = false;
    },
    clearBox(elementID) {
      document.getElementById(elementID).innerHTML = ' ';
    },
  },

};
</script>
